import React from 'react'
import Helmet from 'react-helmet'
import PhotoGallery from '../components/photogallery'
import Faq from '../components/faq'
import Team from '../components/team'
import Contatti from '../components/contatti'
import Breadcrumbs from '../components/breadcrumbs'
import Placeholder from '../components/placeholder'
import Layout from "../components/layout"
import DocumentiUtili from '../components/documenti-utili'
import { graphql } from "gatsby"

class ServizioTemplate extends React.Component {

  render() { 
    const data = this.props.data.contentfulSchedaServizio;
    const jsonfile = this.props.data.allCliniciJson.edges[0].node;
   
    const breadcrumb = [
      {"title":jsonfile.name==='Esotici e non convenzionali'?"Servizi":"Reparti","link":jsonfile.name==='Esotici e non convenzionali'?"/servizi/":"/reparti/"},
      {"title":jsonfile.reparto,"link": jsonfile.link},
      {"title":  data.titolo}
    ];

    return (
      <Layout  location={this.props.location}>
        <div className="container">
          <Helmet title={data.titolo}/>
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-md-8">
              <div className="servizi" data-id={data.id}>
                <h1 className="servizi__title">{data.titolo}</h1>
                {!!data.descrizione &&
                  <div className="servizi__bodytext" 
                      dangerouslySetInnerHTML={{ __html: data.descrizione.childMarkdownRemark.html }} >
                  </div>
                }
                {!!data.approfondimento &&
                  <h2>Approfondimenti</h2>
                }  
                <div className="row flex-wrap">
                  {!!data.approfondimento && data.approfondimento.map((item,index) => {
                    let dataserv = { 
                      "title": item.titolo,
                      "link": "/servizi/"+item.friendlyUrl
                      };
                    return (
                      <div key={index} className="col-12">
                        <Placeholder data={dataserv}/>
                      </div>
                    )
                  })

                  }
                </div>
                
                {!!data.imageGallery &&
                  <div className="magnific-popup">
                    <h2 className="servizi__subject">Fotogallery</h2>
                    <PhotoGallery data={data.imageGallery}/>
                  </div>
                }
                {!!data.faq &&
                  <div className="faq">
                    <h2 className="servizi__subject">FAQ</h2>
                    <Faq data={data.faq}/>
                  </div>
                }
              </div>
            </div>
            <div className="col-md-4">
             
              <Team data={data} tipo="c"/>
              {!!data.documentiUtili &&
                <DocumentiUtili data={data.documentiUtili}/>
              }
              <Contatti data={data}/>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

}

export default ServizioTemplate;

export const pageQuery = graphql`query ServizioDetailQuery($contentful_id: String!, $nomespecie: String!) {
  allCliniciJson(filter: {name: {eq: $nomespecie}}) {
    edges {
      node {
    		name
        reparto
    		link
      }
    }
  }
  contentfulSchedaServizio(contentful_id: {eq: $contentful_id}) {
    id
    contentful_id
    titolo
    descrizione {
      childMarkdownRemark {
        html
      }
    }
    specie
    indirizzo
    mail
    contatti {
      childMarkdownRemark {
        html
      }
    }
    documentiUtili {
      title
      file {
        url
      }
    }
    telefono
    team {
      cognome
      nome
    }
    approfondimento {
      id
      friendlyUrl
      titolo
    }
    faq {
      domanda
      risposta {
        childMarkdownRemark {
          html
        }
      }
    }
    imageGallery {
      ...PhotoGalleryFragment
    }
  }
}
`;
